<template>
  <div class="container flex-column">
    <div class="bg"></div>
    <nav-box :title="title" :back="false"></nav-box>
    <div class="content flex-column">
      <div class="tab-header flex flex-sb">
        <div class="tab-header-left">
          <span
            :class="activeMenu == index ? 'on' : ''"
            v-for="(item, index) in consumptionList"
            :key="item.subjectNo"
            @click="jump(index)"

            >{{ item.keyCardCode }}</span
          >
        </div>
        <div class="tab-header-right flex flex-center" @click="showAddBox">
          筛选/新增
          <img v-if="showAdd" src="@/assets/top.png" alt="" />
          <img v-else src="@/assets/bottom.png" alt="" />
        </div>
      </div>
      <div class="tab-box" id="scrollBox">
        <div
          class="tab-item do-jump flex-column"
          v-for="item in consumptionList"
          :key="item.subjectNo"
        >
          <div class="tab-message flex flex-sb">
            <div class="flex">
              <span>手牌号：</span>
              <p>{{ item.keyCardCode }}</p>
            </div>
            <div class="flex">
              <span>入场时间：</span>
              <p>{{ item.entryTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}</p>
            </div>
            <div class="flex">
              <span>消费金额：</span>
              <p class="text-price">{{ item.standardAmount }}</p>
            </div>
          </div>
          <div class="tab-details flex-column">
            <blockquote v-for="ite in item.items" :key="ite.id">
              <div
                class="tab-details-item flex flex-sb"
                v-if="ite.status != 128"
              >
                <div class="message flex-column">
                  <p class="flex">
                    <i
                      class="discount flex flex-center"
                      v-if="ite.discount == 1 || ite.discount == 3"
                      >折</i
                    >
                    <i
                      class="discount flex flex-center"
                      style="background: #ffd15c"
                      v-if="ite.discount == 2 || ite.discount == 3"
                      >会</i
                    >
                    <i class="discount flex flex-center" v-if="ite.given">赠</i>
                    {{ ite.itemName }}
                  </p>
                  <span v-if="ite.itemNature == 1"
                    >技师号 {{ ite.masseurCode }}</span
                  >
                  <span v-else>{{ ite.practices }}</span>
                </div>
                <div class="unit">{{ ite.quantity }}{{ ite.unitName }}</div>
                <div class="verification flex">
                  <span class="flex flex-center" v-if="ite.sourceType == 6"
                    >已核销</span
                  >
                </div>
                <div class="item-price flex-column">
                  <span class="text-price">{{ ite.amount }}</span>
                  <p v-if="ite.discount == 1" class="text-price">
                    {{ ite.standardAmount }}
                  </p>
                </div>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="left flex flex-sb">
        <div class="total-num flex">
          <span>合计</span>
          <p>{{ totalNum }}项</p>
          <div class="total-details flex" @click="toggleTotal">
            优惠详情<van-icon name="arrow-down" />
          </div>
        </div>
        <div class="total-price flex">
          <div class="total flex-column">
            <span>总计</span>
            <p class="text-price">{{ details.standardAmount }}</p>
          </div>
          <div class="total flex-column">
            <span>优惠</span>
            <p class="text-price">{{ details.discountAmount }}</p>
          </div>
          <div class="total flex-column">
            <span>已付金额</span>
            <p class="text-price">{{ details.paidAmount }}</p>
          </div>
          <div class="total flex-column" style="color: #f5d6b1">
            <span>待付金额</span>
            <p class="text-price">{{ details.unPaidAmount }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="right flex flex-center" @click="goPay">去支付</div> -->
      <div class="details flex-column" v-show="showTotal">
        <div class="details-title flex flex-sb">
          <span>消费金额</span>
          <div class="flex flex-center" @click="showTotal = false">
            <van-icon name="cross" size="16" />
          </div>
        </div>
        <div class="details-price flex flex-sb">
          <div class="details-item flex-column">
            <div class="item flex flex-sb">
              <span>消费金额</span>
              <div>
                <p class="text-price">{{ details.standardAmount }}</p>
              </div>
            </div>
            <div class="item flex flex-sb">
              <span>优惠金额</span>
              <div class="flex">
                <div class="discount" v-if="details.discount == 1">折</div>
                <p class="text-price">{{ details.discountAmount }}</p>
              </div>
            </div>
            <div class="item flex flex-sb">
              <span>折后金额</span>
              <div>
                <p class="text-price">0</p>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="details-item flex-column">
            <div class="item flex flex-sb">
              <span>已收款金额</span>
              <div>
                <p class="text-price">{{ details.paidAmount }}</p>
              </div>
            </div>
            <div class="item flex flex-sb">
              <span>待收款金额</span>
              <div>
                <p class="text-price">{{ details.unPaidAmount }}</p>
              </div>
            </div>
            <div class="item flex flex-sb">
              <span>开票金额</span>
              <div>
                <p class="text-price">0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-box flex">
      <div class="pay-list flex flex-sb">
        <div
          class="departure flex flex-center"
          v-if="!isPrepayment && !isRecycler"
          @click="checkedDeparture"
        >
          <img v-if="departure" src="../../../assets/checked.png" alt="" />
          <img v-else src="../../../assets/check1.png" alt="" />
          <span>是否买单不离场</span>
        </div>
        <div
          v-if="details.unPaidAmount != 0"
          class="pay-btn flex flex-center"
          @click="goPay('memberCard')"
        >
          会员卡
        </div>
        <div
          v-if="details.unPaidAmount != 0"
          class="pay-btn flex flex-center"
          @click="goPay('discount')"
        >
          我用券
        </div>
        <div
          v-if="details.unPaidAmount != 0"
          class="pay-btn flex flex-center"
          @click="goPay('wxPay')"
        >
          微信/支付宝
        </div>
        <div
          v-if="details.unPaidAmount === 0"
          class="pay-btn flex flex-center"
          @click="settleAccountSet"
        >
          结账
        </div>
      </div>
    </div>
    <!-- 取消结账 -->
    <div class="cancel flex flex-center" @click="goCancel">取消结账</div>
    <!-- 显示添加手牌 -->
    <div class="add-box flex-column" v-if="showAdd">
      <div class="keyCard-box flex-column">
        <!-- <div class="keyCard-list flex">
          <div
            class="item flex flex-center"
            :class="item.checked ? 'on' : ''"
            @click="checkedCard(item)"
            v-for="item in addData"
            :key="item.keyCardCode"
          >
            {{ item.keyCardCode }}
          </div>
        </div> -->
        <div class="keyCard-type flex">
          <div class="keyCard-type-box flex">
            <div
              class="keyCard-type-item"
              :class="item.checked ? 'on' : ''"
              v-for="item in addData"
              :key="item.consumptionSubjectId"
              @click="checkedCard(item)"
            >
              <span class="flex flex-center">{{ item.keyCardCode }}</span>
              <blockquote
                v-for="ite in item.children"
                :key="ite.consumptionSubjectId"
              >
                <p class="flex flex-center">{{ ite.keyCardCode }}挂</p>
              </blockquote>
            </div>
          </div>
        </div>
        <div class="keyCard-bottom flex flex-sb">
          <div class="tips flex">
            <div style="margin-right: 8px"><van-icon name="warning-o" /></div>
            <p>温馨提示：刷手牌后，将会新增手牌</p>
          </div>
          <div class="btn flex">
            <div class="flex flex-center" @click="cancelCard">取消</div>
            <div class="affirm flex flex-center" @click="affirmCard">确认</div>
          </div>
        </div>
      </div>
      <div class="scan flex flex-column flex-center">
        <img src="@/assets/key.png" alt="" />
        <p>请在下方刷同行手牌，一起结账</p>
        <input
          type="text"
          ref="input"
          class="box"
          v-model="KeyCard"
          @keyup.enter="swipingCard"
        />
      </div>
    </div>
    <!-- 显示扫码 -->
    <div class="pay-popup flex-column" v-if="showPay">
      <div class="pay-window flex-column flex-center">
        <div class="pay-tip flex-column flex-center">
          <p>
            请将{{
              payType == "wxPay"
                ? "微信/支付宝付款码"
                : "本人小程序“会员二维码”"
            }}置于
          </p>
          <p>自助机下方识别区</p>
          <div class="pay-img">
            <img class="scan-img" src="@/assets/scan.png" alt="" />
          </div>
          <div class="cancel-account flex flex-center" @click="cancelAccount">
            取消结账
          </div>
        </div>
        <div class="scan-box">
          <input
            type="text"
            ref="input1"
            class="box"
            v-model="barcode"
            @keyup.enter="enterPay"
          />
        </div>
      </div>
    </div>

    <!-- 显示含有未完成项目提示 -->
    <div class="pay-popup flex-column" v-if="showUnfinished">
      <div class="unfinished flex-column flex-center">
        <div class="unfinished-tip flex-column flex-center">
          <span class="title">您有未停止计时项目</span>
          <span class="tip">无法使用自助机结账，请移步收银台结账</span>
          <div class="unfinished-img">
            <img class="scan-img" src="@/assets/unfinished.png" alt="" />
          </div>
          <div class="cancel-account flex flex-center" @click="goHome">
            返回首页（{{ time }}S）
          </div>
        </div>
      </div>
    </div>
    <!-- 显示买单不离场手牌 -->
    <div class="pay-popup flex-column" v-if="showDeparture">
      <div class="departure-box flex-column flex-center">
        <div class="departure flex-column">
          <div class="departure-title">请选择“买单不离场”手牌</div>
          <div class="departure-card flex">
            <div
              class="departure-card-item flex"
              v-for="item in departureData"
              :key="item.consumptionSubjectId"
              @click="selectedCard(item)"
            >
              <img
                v-if="item.checked"
                src="../../../assets/checked.png"
                alt=""
              />
              <img v-else src="../../../assets/check2.png" alt="" />
              <span>{{ item.keyCardCode }}</span>
            </div>
          </div>
          <div class="departure-btn flex flex-sb">
            <div
              class="close btn-item flex flex-center"
              @click="showDeparture = false"
            >
              取消
            </div>
            <div class="btn-item flex flex-center" @click="departureAffirm">
              确认
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 显示手牌备注弹窗 -->
    <div class="pay-popup flex-column" v-if="isShowRemark">
      <div class="remark-box flex-column flex-center">
        <div class="remark flex-column">
          <span class="remark-title">手牌备注</span>
          <div class="remark-content flex-column">
            <div class="remark-name">团队备注</div>
            <div class="remark-list flex-column">
              <div
                class="remark-item flex-column"
                v-for="item in teamRemark"
                :key="item.id"
              >
                <p class="remark-text">
                  {{ item.content }}
                </p>
                <div class="remark-message flex">
                  <div class="remark-time flex">
                    <p style="margin-left: 0">{{ item.realName }}</p>
                    <p>{{ item.createdTime | dateFormat }}</p>
                  </div>
                </div>
              </div>
              <div
                class="remark-item flex-column"
                v-if="teamRemark.length == 0"
              >
                <p class="remark-text">暂无备注</p>
              </div>
            </div>
            <div class="remark-name">个人备注</div>
            <div class="remark-list flex-column" style="margin-bottom: 0">
              <div
                class="remark-item flex-column"
                v-for="item in remarkList"
                :key="item.id"
              >
                <p class="remark-text">
                  {{ item.content }}
                </p>
                <div class="remark-message flex">
                  <span>手牌号：{{ item.keyCardCode }}</span>
                  <div class="remark-time flex">
                    <p>{{ item.realName }}</p>
                    <p>{{ item.createdTime | dateFormat }}</p>
                  </div>
                </div>
              </div>
              <div
                class="remark-item flex-column"
                v-if="remarkList.length == 0"
              >
                <p class="remark-text">暂无备注</p>
              </div>
            </div>
          </div>
          <div
            class="remark-close flex flex-center"
            @click="isShowRemark = false"
          >
            关闭
          </div>
        </div>
      </div>
    </div>
    <!-- 会员卡支付弹窗 -->
    <div class="member flex-column" v-if="showMemberCard">
      <div class="nav-box">
        <div class="nav-content flex flex-center">
          <div class="back flex flex-center" @click="closeMember">
            <img src="../../../assets/back.png" alt="" />
            <span>返回</span>
          </div>
          <p class="title">会员卡支付</p>
        </div>
      </div>
      <div class="member-content flex-column">
        <span class="member-title">请确认会员卡信息</span>
        <div class="member-message flex flex-sb">
          <div class="flex">
            <span>会员姓名：</span>
            <p>{{ memberCardMessage.guestCName }}</p>
          </div>
          <div class="flex">
            <span>有效期：</span>
            <p>{{ memberCardMessage.mbrExpired }}</p>
          </div>
        </div>
        <div class="member-card flex-column">
          <div class="member-card-top flex-column">
            <span>{{ memberCardMessage.mbrCardTypeName }}</span>
            <p>NO.{{ memberCardMessage.mbrCardNo }}</p>
          </div>
          <div class="member-card-bottom flex flex-sb">
            <div class="flex-column">
              <span>余额</span>
              <p class="text-price">{{ memberCardMessage.accountBalance }}</p>
            </div>
            <div class="flex-column">
              <span>储值</span>
              <p class="text-price price">{{ memberCardMessage.baseAmtBalance }}</p>
            </div>
            <div class="flex-column">
              <span>赠送</span>
              <p class="text-price price">{{ memberCardMessage.incamount }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="member-footer flex-column">
        <div class="tips flex flex-center" v-if="memberCardCheckMessage.message">{{memberCardCheckMessage.message}}</div>
        <div class="member-total flex flex-sb">
          <div class="left flex">
            <span>本次使用</span>
          <p>
            {{ memberCardMessage.mbrCardTypeName }}
          </p>
          </div>
          <div class="right flex">
            <div class="right-item flex-column">
              <span>储值</span>
              <p>{{memberCardCheckMessage.baseAmount}}</p>
            </div>
            <div class="right-item flex-column">
              <span>增值</span>
              <p>{{memberCardCheckMessage.giveAmount}}</p>
            </div>
            <div class="right-item flex-column">
              <span>使用券</span>
              <p>{{memberCardCheckMessage.ticketAmount}}</p>
            </div>
            <div class="right-item flex-column">
              <span>应付金额</span>
              <p>{{details.unPaidAmount}}</p>
            </div>
          </div>
        </div>
        <div class="member-btn flex">
          <div class="cancel flex flex-center" @click="closeMember">取消</div>
          <div class="save flex flex-center" @click="memberPay">确认支付</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
import moment from "moment";
import { Toast } from "vant";
import axios from "axios";
import $ from "jquery";
import { swallowKeyNo,spitoutKeyNo } from "@/utils/recycler";
export default {
  components: {
    navBox,
  },
  name: "list",
  data() {
    return {
      title: localStorage.getItem("storeName"),
      showTotal: false,
      KeyCard: "",
      details: {}, //详情数据
      consumptionList: [], //消费列表
      activeMenu: 0,
      showAdd: false,
      addData: [],
      consumptionSubjectRelationId: "", //组合id
      topArr: [],
      timer: null,
      consuptionSubjectIds: [],
      dataSource: [],
      barcode: "",
      showPay: false, //显示扫码
      payment: [],
      payMethodId: "",
      showUnfinished: false, //显示未完成项目
      time: 0,
      timerId: "",
      departure: false,
      showDeparture: false,
      departureCard: [],
      departureData: [], // 不离场手牌弹窗数据源
      noDepartureList: [], //不离场手牌列表
      payType: "", //支付类型   微信/支付宝  用券  会员卡
      showMemberCard: false,
      memberCardMessage: {}, //会员卡信息
      isShowRemark: false, // 是否显示备注弹窗
      remarkCode: 0, // 后台系统参数是否显示备注弹窗   0 不显示  1 显示
      teamRemark: [], // 团队备注
      remarkList: [], // 个人备注
      isPrepayment: localStorage.getItem("type") == "先付模式" ? true : false, //是否先付模式
      cardItems: [], // 所有手牌下的所有项目
      memberCardCheckMessage: {},
      isRecycler:false,//自助机程序是否启用手牌回收箱
    };
  },
  created() {
    console.log('created');
    // this.getList();
  },
  filters: {
    dateFormat: function (dateStr, pattern = "YYYY-MM-DD HH:mm:SS") {
      if (!dateStr) {
        return "-";
      }
      return moment(dateStr).format(pattern);
    },
  },
  watch: {
    time: function (newVal, oldVal) {
      if (newVal == 0) {
        this.goHome()
      }
    },
  },
  beforeMount() {},
  async mounted() {
    this.getRemarkCode();
    this.getDepartureSet();
    // 自助机程序是否启用手牌回收箱
    await this.getRecycler();
    await this.getList();
    await this.getPayment();
    // 获取滚动dom元素
    this.$nextTick(() => {
      let that = this;
      that.scrollBox = document.getElementById("scrollBox");

      // 监听dom元素的scroll事件
      that.scrollBox.addEventListener("scroll",
        () => {
          const current_offset_top = that.scrollBox.scrollTop;
          for (let i = 0; i < that.topArr.length; i++) {
            if (current_offset_top <= that.topArr[i]) {
              // 根据滚动距离判断应该滚动到第几个导航的位置
              that.activeMenu = i;
              break;
            }
          }
        },
        true
      );
    });
  },
  computed: {
    totalNum() {
      let arr = [];
      this.consumptionList.forEach((item) => {
        item.items.forEach((ite) => {
          if (ite.status != 128) {
            arr.push(ite);
          }
        });
      });
      return arr.length;
    },
  },
  methods: {
    // 获取备注弹窗系统参数
    getRemarkCode() {
      this.$fetchNo(this.Api.getSettingCode + "/001003003").then((res) => {
        this.remarkCode = res.data.value;
      });
    },
    // 自助机程序是否启用手牌回收箱
    async getRecycler() {
      await this.$fetchNo(this.Api.getSettingCode + "/001006005").then((res) => {
        this.isRecycler = (res.data.value == "1" && localStorage.getItem("type") == "自助结账") ? true : false;
      });
    },
    // 获取系统参数设置  买单不离场是否勾选
    getDepartureSet() {
      this.$fetchNo(this.Api.getSettingCode + "/001002017").then((res) => {
        // 判断如果不是先付模式的话
        if (!this.isPrepayment)
          this.departure = res.data.value == "0" ? false : true;
      });
    },
    // 选择是否买单不离场
    checkedDeparture() {
      this.departure = !this.departure;
    },
    // 返回首页
    goHome() {
      if(this.isRecycler){
        spitoutKeyNo(localStorage.getItem("keyCard")).then(()=>{
          localStorage.setItem("keyCard", "");
          this.$router.push("/welcome");
        });
      }else{
        localStorage.setItem("keyCard", "");
        this.$router.push("/welcome");
      }
    },
    swipingCard() {
      this.getSingleByCode(this.KeyCard);
      this.KeyCard = "";
    },
    toggleTotal() {
      this.showTotal = !this.showTotal;
    },
    // 显示新增手牌
    showAddBox() {
      this.showAdd = !this.showAdd;

      if (this.showAdd) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
        this.addData = this.dataSource.map((item) => {
          return {
            consumptionSubjectId: item.consumptionSubjectId,
            keyCardCode: item.keyCardCode,
            checked: true,
            children: item.children,
          };
        });
        console.log(this.addData);
      } else {
        this.KeyCard = "";
      }
    },
    // 选择、取消手牌
    checkedCard(item) {
      item.checked = !item.checked;
      this.$refs.input.focus();
    },
    getSingleByCode(keyCard) {
      this.$fetch(this.Api.singlebycode, { code: keyCard }).then((result) => {
        var that = this;
        if (result.code == 1) {
          that.$fetch(`/guest/1.0/ConsumptionSubjectRelation/Certificate/1/${result.data.code}?include=5`)
            .then((res) => {
              let consumptionSubjectIds = res.data.details.map((item) => {
                return item.consumptionSubjectId;
              });
              let data = {
                consumptionAreaId: localStorage.getItem("consumptionAreaId"),
                consuptionSubjectIds: consumptionSubjectIds,
                settlementType: 1,
                real: false,
              };
              // 调单
              that.$post(that.Api.transferOrder, data).then((rest) => {
                // 防止加入的手牌也是组合
                rest.data.details.forEach((item) => {
                  that.addData.unshift({
                    consumptionSubjectId: item.consumptionSubjectId,
                    keyCardCode: item.keyCardCode,
                    checked: true,
                    children: item.children,
                  });
                });
              });
            });
        }
      });
    },
    // 获取支付方式
    getPayment() {
      this.$fetch(this.Api.payMent).then((res) => {
        this.payment = res.data.items.filter((item) => {
          // 扫呗条码  16   支付宝条码 64   微信条码  256    2为会员卡
          return (
            item.handleType == 64 ||
            item.handleType == 256 ||
            item.handleType == 16 ||
            item.handleType == 2
          );
        });
      });
    },
    // 扫码回车
    enterPay() {
      var that = this;
      if (that.payType == "memberCard") {
        if (that.barcode.includes("code:")) {
          that.barcode = that.barcode.substring(5);
        }
        // 根据会员小程序二维码解码出卡号
        that.$fetch(that.Api.QrCode, { content: that.barcode }).then((res) => {
          // 根据卡号查会员卡详情
          that.$fetch(that.Api.MemberCard, { content: res.data })
            .then((res) => {
              if(res.data.length==0){
                Toast('无此会员卡，或会员码过期');
                return;
              }
              that.memberCardMessage = res.data[0];
              that.$post(that.Api.MemberCheck, {
                  canItBeUsedBaseAmountBalance: false,
                  isBaseAmount: false,
                  consumptionAreaId: localStorage.getItem("consumptionAreaId"),
                  cards: [
                    {
                      amount: that.details.unPaidAmount,
                      mamberCardNo: that.memberCardMessage.mbrCardNo,
                      ticketNos: null,
                    },
                  ],
                  details: that.cardItems,
                })
                .then((res) => {
                  that.memberCardCheckMessage = res.data.memberCards[0];
                  // let total = this.memberCardCheckMessage.baseAmount+this.memberCardCheckMessage.giveAmount+this.memberCardCheckMessage.ticketAmount
                  // console.log(total)
                  that.showMemberCard = true;
                  that.$refs.input1.blur();
                });
            });
        });
      } else {
        // 判断   支付方式如果有扫呗支付则优先使用扫呗，
        // 没有扫呗在根据扫码的数据判断是微信还是支付宝
        let payMentList = that.payment.filter((item) => item.handleType == 16);
        let wxpay = that.payment.filter((item) => item.handleType == 256);
        let alipay = that.payment.filter((item) => item.handleType == 64);
        if (payMentList.length > 0) {
          that.payMethodId = payMentList[0].id;
        } else {
          if (that.isValidWeChat(that.barcode)) {
            that.payMethodId = wxpay[0].id;
          }
          if (that.isValidAlipay(that.barcode)) {
            that.payMethodId = alipay[0].id;
          }
        }
        that.payOrder(that.barcode);
        that.barcode = "";
        that.$refs.input1.focus();
      }
    },
    // 校验是否微信二维码
    isValidWeChat(str) {
      const weChatPattern = /^(10|11|12|13|14|15)\d{16}$/;
      return weChatPattern.test(str);
    },
    // 校验是否支付宝二维码
    isValidAlipay(str) {
      const alipayPattern = /^(25|26|27|28|29|30)\d{14,22}$/;
      return alipayPattern.test(str);
    },
    // 会员卡支付点击确定
    memberPay() {
      this.barcode = "";
      this.payOrder("");
    },
    // 发起支付
    async payOrder(barcode) {
      let total = 0
      var consumptionAreaId = localStorage.getItem("consumptionAreaId");
      let data = {
        consumptionAreaId: consumptionAreaId,
        consumptionSubjectId:this.consuptionSubjectIds.length > 1
            ? this.consuptionSubjectIds[0]
            : this.consuptionSubjectIds.join(""),
        payMethodId: this.payMethodId,
        amount: this.details.unPaidAmount,
        // amount: 54,
        barcode: barcode,
      };
      if (this.payType == "memberCard") {
        let baseAmount = 0;
        // 计算出该会员卡最大能抵扣多少金额    储值最大能抵扣金额  +  增值最大能抵扣金额  + 券最大能抵扣金额
        total =
          this.memberCardCheckMessage.baseAmount +
          this.memberCardCheckMessage.giveAmount +
          this.memberCardCheckMessage.ticketAmount;
        baseAmount =
          total >= this.details.unPaidAmount
            ? this.details.unPaidAmount
            : total;
        data.extend = {
          balanceType: "0102", //01:储值, 02:增值, 11: 积分, 0102: 储值+增值
          // 判断订单金额是否大于会员卡余额    如果大于的话就把余额全部传入
          memberCardId: this.memberCardMessage.memberCardId,
          memberCardNo: this.memberCardMessage.mbrCardNo,
          password: "",
          baseAmount: JSON.stringify(this.memberCardCheckMessage.realBaseAmount),
          tickets: JSON.stringify(this.memberCardCheckMessage.tickets)
        };
        data.amount = baseAmount
      }
      console.log(data);
      // 支付
      this.$post(this.Api.pay, data).then((res) => {
        let that = this;
        // 支付成功
        if (res.code == 1) {
          if (that.payType == "memberCard") {
            if(total < that.details.unPaidAmount){
              that.showMemberCard = false;
              that.showPay = false;
              that.getOrder(that.consuptionSubjectIds);
              return;
            }
          }

          Toast.loading({
            message: "正在结账...",
            forbidClick: true,
          });

          if (that.isPrepayment) {//预付模式直接打印入场单
            //打印入场单
            that.printAdmissionTicket()

            //结账吞卡
            if(this.isRecycler){
              swallowKeyNo(localStorage.getItem("keyCard"))
            }

          } else {
            //买单不离场
            if(that.departure){
              that.settleAccount(res.data.details, res.data.id);
            }else{
              that.settleAccount1();
            }
          }

        }
      });
    },
    async printAdmissionTicket(){//打印入场单
      const that = this;
      let requestData = await this.requestUrlOrBody({type: "P10"});
      var arr = [];
      if (that.departure) {
        // 获取离场的手牌 打印入场单
        arr = that.departureData
          .filter((item) => !item.checked)
          .map((item) => {
            return {
              KeyNo: item.keyCardCode,
            };
          });
      } else {
        arr = that.consumptionList.map((item) => {
          return {
            KeyNo: item.keyCardCode,
          };
        });
      }

      arr.forEach((item, index) => {
        setTimeout(() => {
          that.dataSet(requestData.body, {KeyNo:item});
        }, 3000 * (index)); // 每隔1.5秒执行一次
      });

      setTimeout(() => {
        that.$router.push({
          path: "/result",
          query: {
            payType: "payment",
            result: true,
          },
        });
      }, 3000);
    },
    settleAccountSet() {
      if (this.departure) {
        this.settleAccount();
      } else {
        this.settleAccount1();
      }
    },

    // 结账和不离场
    settleAccount() {
      let that = this;
      // 订单手牌消费主体id列表
      let items = that.consumptionList.map((item) => item.consumptionSubjectId);
      // 订单手牌号列表
      let codesList = that.consumptionList.map((item) => item.keyCardCode);
      // 买单不离场手牌消费主体id列表
      let reReceptionConsuptionSubjectIds = that.departureData
        .filter((item) => item.checked)
        .map((item) => item.consumptionSubjectId);
      let formData = {
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        consuptionSubjectIds: this.consuptionSubjectIds,
        settlementType: 1,
        description: "",
        orderId: that.details.id,
        removedCertificateCodes: [],
        srcCertificateCodes: codesList, //结账手牌号
        terminalId: localStorage.getItem("terminalId") ? localStorage.getItem("terminalId") : "9",
      };
      if (that.isPrepayment) {
        formData.reReceptionConsuptionSubjectIds = items;
      } else if (that.departure) {
        formData.reReceptionConsuptionSubjectIds = reReceptionConsuptionSubjectIds;
      } else {
        formData.reReceptionConsuptionSubjectIds = [];
      }

      that.$post(that.Api.NotLeave, formData)
        .then(async (res) => {
          that.showPay = false;
          Toast.clear();

          //结账吞卡
          if(this.isRecycler){
            spitoutKeyNo(localStorage.getItem("keyCard"))
          }

          that.$toast.loading({
            message: "正在打印中，请稍后....",
            forbidClick: true,
            duration: 3000,
          });

          // 获取离场手牌打印离场单
          var arr = that.departureData
            .filter((item) => !item.checked)
            .map((item) => {
              return {
                KeyNo: item.keyCardCode,
              };
            });

          setTimeout(() => {
            that.PrintReport(res.data.id);
          }, 1500);

          setTimeout(() => {
            that.printShoes(res.data.needSendShoeCabinetNotifySubjectIds);
          }, 400);
          
          setTimeout(() => {
            that.printDeparture(arr);
          }, 500);

          setTimeout(() => {
            that.$router.push({
              path: "/result",
              query: {
                payType: "payment",
                result: true,
              },
            });
          }, 1500);

        })
        .catch((err) => {
          console.log(err);
          // 结账失败 重新调单
          // Toast(err.response.data.message);
          that.showPay = false;
          setTimeout(function () {
            that.cancelAccount();
            that.getList();
            that.getPayment();
          }, 1500);
        });
    },

    // 结账
    settleAccount1() {
      let fromData = {
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        consuptionSubjectIds: this.consuptionSubjectIds,
        real: true,
        settlementType: 1,
        // orderId: res.data.payId,
      };
      // 结账
      this.$post(this.Api.transferOrder, fromData)
        .then((res) => {
          this.showPay = false;
          // 结账成功
          if (res.code == 1) {
            Toast.success("结账成功");
            var that = this;
            var arr = [];
            // 如果勾选了买单不离场
            if (this.departure) {
              // 获取离场的手牌 打印离场单
              arr = that.departureData
                .filter((item) => !item.checked)
                .map((item) => {
                  return {
                    KeyNo: item.keyCardCode,
                  };
                });
            } else {
              // 获取离场手牌打印离场单
              var list = that.getFlatArr(res.data.details);
              arr = list.map((item) => {
                return {
                  KeyNo: item.keyCardCode,
                };
              });
            }

            //结账吞卡
            if(this.isRecycler){
              swallowKeyNo(localStorage.getItem("keyCard"))
            }

            that.PrintReport(res.data.id);
            // if (that.payType == "memberCard") {
            //   that.PrintCheckOutMember(res.data.id);
            // }
            setTimeout(() => {
              that.printShoes(res.data.needSendShoeCabinetNotifySubjectIds);
            }, 400);
            setTimeout(() => {
              that.printDeparture(arr);
            }, 1500);

            setTimeout(() => {
              that.$router.push({
                path: "/result",
                query: {
                  payType: "payment",
                  result: true,
                },
              });
            }, 1500);
          }
        })
        .catch((err) => {
          // 结账失败 重新调单
          Toast(err.response.data.message);
          this.showPay = false;
          var that = this;
          setTimeout(function () {
            that.cancelAccount();
            that.getList();
            that.getPayment();
          }, 1500);
        });
    },

    async printOrder(data, arr) {
      let results = false;
      try {
        await this.PrintReport(data.id);
        await this.printShoes(data.needSendShoeCabinetNotifySubjectIds);
        await this.printDeparture(arr);
        results = true;
      } catch (error) {
        results = false;
      }
      return results;
    },

    // 取消结账
    goCancel() {
      if (this.isPrepayment) {
        this.$router.push("/prepayment");
      } else {
        this.goHome()
      }
    },
    cancelAccount() {
      this.showPay = false;
      this.barcode = "";
    },
    // 取消
    cancelCard() {
      this.showAdd = false;
    },
    // 确定
    async affirmCard() {
      this.showAdd = false;
      let consuptionSubjectIds = this.addData
        .filter((item) => {
          return item.checked;
        })
        .map((item) => {
          return item.consumptionSubjectId;
        });
      this.consuptionSubjectIds = consuptionSubjectIds;
      if (this.addData.length > consuptionSubjectIds.length) {
        let fromData = {
          consumptionAreaId: localStorage.getItem("consumptionAreaId"),
          consumptionSubjectIds: this.addData
            .filter((item) => {
              return !item.checked;
            })
            .map((item) => {
              return item.consumptionSubjectId;
            }),
          consumptionSubjectRelationId: this.consumptionSubjectRelationId,
        };
        // 拆分
        await this.$put(this.Api.remove, fromData);
      } else {
        // 追加成员  单个组合
        let fromData = {
          consumptionSubjectIds: consuptionSubjectIds,
          consumptionSubjectRelationId: this.consumptionSubjectRelationId,
        };
        // 组合
        await this.$post(this.Api.group, fromData);
      }
      await this.getOrder(consuptionSubjectIds);
    },
    // 获取手牌列表
    async getList() {

      if(this.isRecycler){
        let consumptionSubjectId = localStorage.getItem("consumptionSubjectId");
        this.consuptionSubjectIds = [consumptionSubjectId]
        this.getOrder(this.consuptionSubjectIds);
      }else{
        let keyCard = localStorage.getItem("keyCard");
        // 通过单个手牌获取单个或多个消费id
        let idList = await this.$fetch(
          `/guest/1.0/ConsumptionSubjectRelation/Certificate/1/${keyCard}?include=5`
        );
        this.consumptionSubjectRelationId = idList.data.id;
        let consuptionSubjectIds = idList.data.details.map((item) => {
          return item.consumptionSubjectId;
        });
        this.consuptionSubjectIds = consuptionSubjectIds;

        this.getOrder(consuptionSubjectIds);
      }
    },
    // 调单
    getOrder(consuptionSubjectIds) {
      let data = {
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        consuptionSubjectIds: consuptionSubjectIds,
        settlementType: 1,
        real: false,
        verifyCharge:this.isRecycler
      };
      // 调单
      this.$post(this.Api.transferOrder, data).then((res) => {
        this.details = res.data;
        // 如果含有未完成项目这显示弹窗提示去收银台结账
        if (!res.data.isCompleted) {
          this.showUnfinished = true;
          this.time = 5;
          this.timerId = setInterval(this.countDown, 1000);
        }
        this.dataSource = res.data.details;
        this.consumptionList = this.getFlatArr(res.data.details);
        let arr = [];
        this.consumptionList.forEach((item) => {
          arr.push(...item.items);
        });
        this.cardItems = arr;
        var that = this;
        that.teamRemark = res.data.comments;
        var remarkList = [];
        res.data.details.forEach((item) => {
          item.comments.forEach((ite) => {
            ite.keyCardCode = item.keyCardCode;
            remarkList.push(ite);
          });
        });
        that.remarkList = remarkList;
        if (
          that.remarkCode == 1 &&
          (that.remarkList.length > 0 || that.teamRemark.length > 0)
        ) {
          that.isShowRemark = true;
        }
        that.$nextTick(() => {
          const jump = $(".do-jump");
          const topArr = [];
          for (let i = 0; i < jump.length; i++) {
            topArr.push(
              jump.eq(i).position().top + jump.eq(i).outerHeight() - 100
            );
          }
          that.topArr = topArr;
        });
      });
    },
    countDown() {
      this.time--;
    },
    // 重构数组
    getFlatArr(array) {
      return array.reduce((a, item) => {
        let flatArr = [...a, item];
        if (item.children) {
          flatArr = [...flatArr, ...this.getFlatArr(item.children)];
        }
        return flatArr;
      }, []);
    },
    // tab栏跳转
    jump(index) {
      this.activeMenu = index; // 当前导航
      const jump = $(".do-jump").eq(index);
      console.log(jump.position().top);
      const scrollTop = jump.position().top + this.scrollBox.scrollTop - 100; // 获取需要滚动的距离
      // Chrome
      this.scrollBox.scrollTo({
        top: scrollTop,
        behavior: "smooth", // 平滑滚动
      });
    },
    removeBase64Prefix(base64String) {
      return base64String.replace(/^data:.*?base64,/, "");
    },
    // 搜索报表
    dataSet(body, data) {
      var that = this;
      var num = data.printCount ? data.printCount : 0;
      var invoiceAddressValue = data.invoiceAddress ? data.invoiceAddress : "";
      //创建报表对象
      var report = new window.Stimulsoft.Report.StiReport();
      // 加载文件
      report.load(body);
      //新建数据源
      var dataSet = new Stimulsoft.System.Data.DataSet("SimpleDataSet");
      //数据源填充数据
      delete data.printCount;
      delete data.invoiceAddress;
      dataSet.readJson(JSON.stringify(data));
      //给报表的数据源填充数据

      let storeName = localStorage.getItem("storeName") || "云水疗";
      report.regData(dataSet.dataSetName, storeName, dataSet);
      var variableString = report.dictionary.variables.getByName("StoreName");
      variableString.value = storeName;
      if (num) {
        var printCount = report.dictionary.variables.getByName("printCount");
        printCount.value = num;
      }
      if (invoiceAddressValue) {
        var invoiceAddress =
          report.dictionary.variables.getByName("invoiceAddress");
        invoiceAddress.value = invoiceAddressValue;
      }

      // report.regBusinessObject("StoreName", "酒店名称");
      // 创建报表
      viewer.report = report;
      report.renderAsync(async function () {
        if (typeof Android2JS != "undefined") {
          // 当报告渲染完成后导出为PNG图片
          report.exportDocumentAsync(function (htmlString) {
            // Write HTML text to DIV element.
            var container = document.getElementById("htmlContainer");
            container.innerHTML = htmlString;
            html2canvas(document.getElementById("htmlContainer")).then(
              (canvas) => {
                // 创建一个新的canvas来调整图像尺寸
                var ctx = canvas.getContext("2d");
                var canvasCopy = document.createElement("canvas");
                var copyContext = canvasCopy.getContext("2d");

                // 设置新canvas的尺寸
                canvasCopy.width = 576;
                canvasCopy.height = (canvas.height * 576) / canvas.width;

                // 使用drawImage方法按比例缩放原始canvas内容到新的canvas
                copyContext.drawImage(
                  canvas,
                  0,
                  0,
                  canvas.width,
                  canvas.height,
                  0,
                  0,
                  canvasCopy.width,
                  canvasCopy.height
                );
                var imgData = that.removeBase64Prefix(
                  canvasCopy.toDataURL("image/png")
                );
                Android2JS.Print3Lines();
                Android2JS.PrintBitmap(imgData);
                Android2JS.Print3Lines();
                Android2JS.CutPaper();
              }
            );
          }, Stimulsoft.Report.StiExportFormat.Html);
        } else {
          that.loading = false;
          await report.print();
        }
        
      });
    },
    // 封装请求  返回报表请求地址和body
    // 传入获取报表类型如   {type: "P01"},
    async requestUrlOrBody(data) {
      var requestData = {
        requestUrl: "",
        body: "",
      };
      let printData = await this.$fetchNo(
        this.Api.PrintReport + "/" + data.type
      );
      requestData.body = printData.data.body;
      requestData.requestUrl = printData.data.dataInterfaceUrl;
      let reportData = await this.$fetchNo(this.Api.ReportFormat, {
        reportId: printData.data.id,
      });
      if (reportData.data.items.length > 0) {
        let list = reportData.data.items.filter((item) => item.isDefault);
        if (list.length > 0) {
          requestData.body = list[0].body;
        }
      }
      return requestData;
    },
    // 请求报表单据打印
    axiosPrint(requestData, formData) {
      axios
        .post(
          requestData.requestUrl,
          {
            ...formData,
          },
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          this.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打印结账单
    async PrintReport(orderId) {
      //获取打印报表
      let data = {
        type: "P09",
      };
      let requestData = await this.requestUrlOrBody(data);
      var that = this;
      let formData = {
        orderId: orderId,
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
      };
      axios
        .post(
          requestData.requestUrl,
          {
            ...formData,
          },
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          that.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 打印离场单
    async printDeparture(list) {
      console.log(list);
      let res = await this.$fetchNo(this.Api.getSettingCode + "/001002010");
      if (
        res &&
        res.data &&
        res.data.value &&
        !isNaN(res.data.value) &&
        parseInt(res.data.value) > 0
      ) {
        //获取打印报表
        let data = {
          // id:this.orderData.id,
          type: "P08",
        };
        let that = this;
        let requestData = await this.requestUrlOrBody(data);
        // 打印多张离场单
        list.forEach((item, index) => {
          setTimeout(() => {
            that.dataSet(requestData.body, [item]);
            console.log(item.KeyNo); // 输出检查
          }, 3000 * (index)); // 每隔1.5秒执行一次
        });
      }
    },
    // 打印鞋吧
    async printShoes(cashierIds) {
      let res = await this.$fetchNo(this.Api.getSettingCode + "/001002008");
      if (
        res &&
        res.data &&
        res.data.value &&
        !isNaN(res.data.value) &&
        parseInt(res.data.value) > 0
      ) {
        //获取打印报表
        let data = {
          // type: "P05",
        };
        let that = this;
        let requestData = await this.requestUrlOrBody(data);
        axios.get(requestData.requestUrl, {
          params: {
            consumptionSubjectIds: cashierIds.join(","),
          },
          headers: {
            access_token: localStorage.getItem("token"),
            x_req_application_id: 1,
            x_req_terminal: 1,
            x_req_store_id: localStorage.getItem("storeId"),
          },
        })
        .then(function (res) {
          that.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {});
      }
    },
    // 打印会员卡
    async PrintCheckOutMember(orderId) {
      //获取打印报表
      let data = {
        type: "P04",
      };
      let that = this;
      let requestData = await this.requestUrlOrBody(data);
      let formData = {
        orderId: orderId,
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
      };
      axios.post(requestData.requestUrl,{...formData},
        {
          headers: {
            access_token: getToken(),
            x_req_application_id: getApplicationId(),
            x_req_store_id: getStoreId(),
            x_req_terminal: getTerminal(),
          },
        }
      )
      .then(function (res) {
        //如果打印数据中余额与之前读取的一致，则重新计算余额
        res.data.data.memberDetails.forEach((item) => {
          if (that.memberCardMessage.memberCardId == item.memberCardId) {
            if (
              item.accountBalance == that.memberCardMessage.accountBalance
            ) {
              item.accountBalance = that.memberCardMessage.accountBalance -
                (item.consumptionBaseAmount + item.consumptionFreeAmount);
            }
          }
        });
        that.dataSet(body, res.data.data);
      })
      .catch(function (error) {});
    },

    // 不离场手牌选择
    selectedCard(item) {
      item.checked = !item.checked;
    },
    // 买单不离场点击确认
    departureAffirm() {
      this.showDeparture = false;
      var that = this;
      if (that.payType == "discount") {
        localStorage.setItem("departureData",JSON.stringify(that.departureData));
        that.$router.push({
          path: "/verification",
          query: {
            departure: that.departure,
          },
        });
      }
      if (that.payType == "wxPay") {
        that.showPay = true;
        that.$nextTick(() => {
          that.$refs.input1.focus();
        });
        // 现金支付  用于测试
        // this.payMethodId = "1b588fe4f9494e0aa6c7908cc0c79f39";
        // this.payOrder("");
      }
      if (that.payType == "memberCard") {
        let payMentList = that.payment.filter((item) => item.handleType == 2);
        if (payMentList.length > 0) {
          that.showPay = true;
          that.payMethodId = payMentList[0].id;
          that.$nextTick(() => {
            that.$refs.input1.focus();
          });
          // that.showMemberCard = true;
        } else {
          Toast("会员卡支付未配置，请联系工作人员");
        }
      }
    },
    // 去支付
    goPay(type) {
      this.departureData = this.consumptionList
        .filter((item) => item.status != 10000)
        .map((item) => {
          return {
            consumptionSubjectId: item.consumptionSubjectId,
            keyCardCode: item.keyCardCode,
            checked: true,
          };
        });
      if (this.departureData.length == 1) {
        this.departureData[0].checked = true;
      }
      this.payType = type;
      let that = this;
      if (that.departure && this.departureData.length > 1) {
        that.showDeparture = true;
      } else {
        if (type == "discount") {
          that.$router.push({
            path: "/verification",
          });
        }
        if (type == "wxPay") {
          that.showPay = true;
          that.$nextTick(() => {
            that.$refs.input1.focus();
          });

          // 现金支付  用于测试
          // this.payMethodId = "1b588fe4f9494e0aa6c7908cc0c79f39";
          // this.payOrder("");
        }
        if (type == "memberCard") {
          // 过滤出会员卡支付
          let payMentList = that.payment.filter((item) => item.handleType == 2);
          if (payMentList.length > 0) {
            that.showPay = true;
            that.payMethodId = payMentList[0].id;
            that.$nextTick(() => {
              that.$refs.input1.focus();
            });
            // that.showMemberCard = true;
          } else {
            Toast("会员卡支付未配置，请联系工作人员");
          }
        }
      }
    },
    // 关闭会员卡弹窗
    closeMember() {
      this.showMemberCard = false;
      this.showPay = false;
      this.barcode = "";
    },
  },
  beforeDestroy() {
    // 清除计时器
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#viewer {
  width: 0 !important;
  height: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  overflow: hidden;
}
.text-price::before {
  content: "¥";
  font-size: 80%;
  margin-right: 4px;
}
.container {
  width: 100%;
  height: 100%;
  // padding-bottom: 108px;
  position: relative;
  background: #f2f2f2;
  .bg {
    background: linear-gradient(
      180deg,
      #cce5ff 0%,
      rgba(242, 243, 247, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    height: 382px;
    width: 100%;
  }
  .content {
    flex: 1;
    padding: 0 43px 40px 43px;
    position: relative;
    z-index: 99;
    overflow: hidden;
    .tab-header {
      margin-bottom: 24px;
      .tab-header-left {
        flex: 1;
        overflow: auto;
        background: #f4f6fa;
        border-radius: 28px;
        height: 56px;
        display: -webkit-inline-box;
        span {
          width: 131px;
          height: 56px;
          border-radius: 28px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 500;
          &.on {
            border: 1px solid #155bd4;
            color: #155bd4;
            background: #fff;
          }
        }
      }
      .tab-header-right {
        width: 143px;
        height: 56px;
        background: #0068f0;
        border-radius: 16px;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        margin-left: 24px;
      }
    }
    .tab-box {
      overflow: auto;
      flex: 1;
      .tab-item {
        margin-bottom: 16px;
        .tab-message {
          width: 100%;
          padding: 20px 24px;
          background: #cee6fe;
          border-radius: 16px 16px 0px 0px;
          font-size: 22px;
          font-weight: 500;
          line-height: 26px;
          color: rgba(26, 30, 34, 0.5);
          margin-bottom: 8px;
          p {
            font-weight: 500;
            font-size: 22px;
            color: #1a1e22;
          }
        }
        .tab-details {
          .tab-details-item {
            width: 100%;
            padding: 16px 24px;
            background: #fff;
            border-radius: 8px;
            margin-bottom: 16px;
            .message {
              flex: 2;
              overflow: hidden;
              margin-right: 40px;
              p {
                width: 100%;
                font-size: 20px;
                line-height: 24px;
                color: #1a1e22;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 8px;
                .discount {
                  width: 20px;
                  height: 20px;
                  margin-top: 2px;
                  border-radius: 4px;
                  margin-right: 6px;
                  font-style: unset;
                  text-decoration: inherit;
                  font-size: 16px;
                  color: #fff;
                  background-color: #d40000;
                }
              }
              span {
                font-size: 18px;
                color: #606876;
                line-height: 22px;
              }
            }
            .unit {
              flex: 1;
              font-size: 20px;
              line-height: 24px;
              color: #606876;
            }
            .verification {
              width: 64px;
              height: 32px;
              span {
                width: 64px;
                height: 32px;
                background-color: #fff7e8;
                color: #ff7d00;
                font-size: 20px;
                font-weight: 500;
                border-radius: 2px;
              }
            }
            .item-price {
              flex: 1;
              align-items: flex-end;
              span {
                font-size: 30px;
                line-height: 38px;
                font-weight: 500;
                color: #0068f0;
                margin-bottom: 4px;
              }
              p {
                font-size: 24px;
                line-height: 26px;
                color: #b9bdc6;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    padding: 0 43px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    height: 96px;
    margin-bottom: 16px;
    .left {
      width: 100%;
      height: 96px;
      background-color: #222c3a;
      border-radius: 16px;
      padding: 0 60px 0 20px;
      box-sizing: border-box;
      align-items: center;
      .total-num {
        span {
          font-size: 30px;
          color: #b9bdc6;
          font-weight: 400;
        }
        p {
          font-size: 30px;
          color: #fff;
          font-weight: 400;
          margin-left: 24px;
        }
        .total-details {
          font-size: 20px;
          font-weight: 700;
          color: #f5d6b1;
          align-items: center;
          margin-left: 24px;
          cursor: pointer;
        }
      }
      .total-price {
        align-items: center;
        color: #fff;
        .total {
          margin-left: 64px;
        }
        span {
          font-size: 20px;
          line-height: 24px;
        }
        p {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    // .right {
    //   width: 272px;
    //   height: 118px;
    //   position: absolute;
    //   right: 43px;
    //   font-size: 32px;
    //   font-weight: 700;
    //   padding-left: 30px;
    //   color: #fff;
    //   background: url("../../../assets/footer-right.png") no-repeat;
    //   background-size: 272px 118px;
    // }
    .details {
      width: 738px;
      height: 198px;
      background: #222c3a;
      border-radius: 16px 16px 0px 0px;
      position: absolute;
      bottom: 110px;
      z-index: 120;
      .details-title {
        padding: 16px 30px;
        color: #fff;
        margin-bottom: 16px;
        span {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
        div {
          width: 60px;
          height: 24px;
        }
      }
      .details-price {
        padding: 0 40px;
        color: #fff;
        .line {
          border: 1px dashed rgba(255, 255, 255, 0.5);
          height: 82px;
          margin: 0 84px;
        }
        .details-item {
          flex: 1;
          .item {
            margin-bottom: 8px;
            span {
              font-size: 14px;
              line-height: 22px;
              color: rgba(255, 255, 255, 0.7);
            }
            div {
              .discount {
                width: 20px;
                height: 20px;
                background: #d40000;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #fff;
              }
              p {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .pay-box {
    padding: 0 43px;
    width: 100%;
    box-sizing: border-box;
    height: 94px;
    .pay-list {
      width: 100%;
      height: 94px;
      background-color: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 12px 24px;
      .departure {
        font-size: 30px;
        color: #0068f0;
        font-weight: bold;
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }
      .pay-btn {
        width: 200px;
        height: 70px;
        background-color: #0068f0;
        color: #fff;
        font-size: 30px;
        font-weight: 500;
        border-radius: 16px;
        cursor: pointer;
      }
    }
  }
  .cancel {
    width: 100%;
    height: 108px;
    box-sizing: border-box;
    padding: 0 43px;
    font-size: 30px;
    font-weight: bold;
    color: #0068f0;
    cursor: pointer;
  }
  .pay-popup {
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);

    .pay-window,
    .unfinished,
    .departure-box {
      width: 100%;
      height: 100%;
      position: relative;
      .pay-tip,
      .unfinished-tip {
        width: 744px;
        height: 780px;
        background-color: #fff;
        border-radius: 32px;
        box-sizing: border-box;
        position: relative;
        z-index: 9999;
        p {
          font-size: 40px;
          line-height: 48px;
          color: #1a1e22;
          font-weight: 500;
        }
        .title {
          font-size: 40px;
          line-height: 48px;
          color: #1a1e22;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .tip {
          font-size: 32px;
          line-height: 38px;
          color: #999999;
          font-weight: 400;
        }
        .pay-img {
          width: 372px;
          height: 372px;
          margin-top: 50px;
          margin-bottom: 30px;
          .scan-img {
            width: 100%;
            height: 100%;
          }
        }
        .unfinished-img {
          width: 544px;
          height: 372px;
          margin-top: 50px;
          margin-bottom: 30px;
          .scan-img {
            width: 100%;
            height: 100%;
          }
        }
        .cancel-account {
          width: 100%;
          padding: 30px 0;
          cursor: pointer;
          font-size: 30px;
          line-height: 36px;
          color: #0068f0;
          font-weight: bold;
        }
      }
      .departure {
        width: 744px;
        background-color: #fff;
        border-radius: 32px;
        box-sizing: border-box;
        position: relative;
        z-index: 999;
        padding: 86px 44px 60px 44px;
        .departure-title {
          font-size: 40px;
          line-height: 48px;
          color: #1a1e22;
          font-weight: bold;
          text-align: center;
          width: 100%;
          margin-bottom: 30px;
        }
        .departure-card {
          flex-wrap: wrap;
          .departure-card-item {
            width: 33.3333%;
            margin-bottom: 56px;
            font-size: 30px;
            color: rgba(10, 25, 41, 0.9);
            font-weight: 500;
            img {
              width: 40px;
              height: 40px;
              margin-right: 8px;
            }
          }
        }
        .departure-btn {
          width: 100%;
          .btn-item {
            width: 318px;
            height: 70px;
            border-radius: 16px;
            font-size: 30px;
            font-weight: bold;
            background-color: #0068f0;
            color: #fff;
            &.close {
              color: #0068f0;
              background-color: #e7f3ff;
            }
          }
        }
      }
      .scan-box {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9998;
        .box {
          width: 100%;
          height: 100%;
          background: transparent;
          font-size: 1px;
          color: transparent;
        }
      }
    }
  }
  .add-box {
    width: 100%;
    height: calc(100% - 270px);
    position: absolute;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    padding: 24px 42px;
    .keyCard-box {
      width: 100%;
      background: #fff;
      border-radius: 16px;
      padding: 24px 45px;
      .keyCard-list {
        flex-wrap: wrap;
        margin-bottom: 10px;
        .item {
          width: 131px;
          height: 56px;
          border-radius: 28px;
          background: linear-gradient(0deg, #f4f6fa, #f4f6fa), #f4f6fa;
          font-size: 24px;
          font-weight: 500;
          // color: #b9bdc6;
          margin-right: 24px;
          margin-bottom: 24px;
          &:nth-child(6n) {
            margin-right: 0;
          }
          &.on {
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.2),
                rgba(255, 255, 255, 0.2)
              ),
              #0068f0;
            color: #fff;
          }
        }
      }
      .keyCard-type {
        width: 100%;
        .keyCard-type-box {
          width: 100%;
          flex-wrap: wrap;
          margin-bottom: 10px;
          .keyCard-type-item {
            margin-right: 24px;
            margin-bottom: 24px;
            display: inline-flex;
            flex-wrap: inherit;
            align-items: center;
            span {
              height: 72px;
              padding: 0 32px;
              background: #e2ecff;
              border-radius: 36px;
              font-size: 28px;
              font-weight: 500;
              color: #3386f3;
            }
            p {
              font-size: 24px;
              height: 56px;
              padding: 0 24px;
              background: #e2ecff;
              border-radius: 38px;
              font-weight: 500;
              color: #3386f3;
              margin-left: 24px;
            }
            &.on {
              span,
              p {
                background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.2),
                    rgba(255, 255, 255, 0.2)
                  ),
                  #0068f0;
                color: #fff;
              }
            }
          }
        }
      }
      .keyCard-bottom {
        .tips {
          font-size: 18px;
          color: #606876;
          line-height: 28px;
        }
        .btn {
          div {
            width: 182px;
            height: 55px;
            margin-left: 24px;
            border-radius: 16px;
            background: #f4f6fa;
            font-size: 16px;
            font-weight: 500;
            color: #155bd4;
            &.affirm {
              color: #fff;
              background: #155bd4;
            }
          }
        }
      }
    }
    .scan {
      position: relative;
      flex: 1;
      p {
        font-size: 26px;
        line-height: 38px;
        color: #fff;
        margin-top: 55px;
      }
      .box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background: transparent;
        font-size: 1px;
        color: transparent;
      }
    }
  }
  .member {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url("../../../assets/bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .member-content {
      box-sizing: border-box;
      padding: 0 58px;
      .member-title {
        font-size: 40px;
        color: #1a1e22;
        font-weight: bold;
        padding-bottom: 40px;
        border-bottom: 3px dotted #b9bdc6;
        text-align: center;
        margin-bottom: 74px;
      }
      .member-message {
        width: 100%;
        box-sizing: border-box;
        padding: 0 55px;
        font-size: 40px;
        color: rgba(26, 30, 34, 0.5);
        line-height: 48px;
        margin-bottom: 73px;
        span {
          font-weight: 500;
        }
        p {
          color: #1a1e22;
          font-weight: 500;
        }
      }
      .member-card {
        width: 100%;
        height: 522px;
        background-image: url("../../../assets/card-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 522px;
        box-sizing: border-box;
        padding: 64px;
        color: #ffffff;
        justify-content: space-between;
        .member-card-top {
          span {
            font-size: 56px;
            line-height: 90px;
            font-weight: bold;
          }
          p {
            font-size: 30px;
            line-height: 44px;
            font-weight: 500;
          }
        }
        .member-card-bottom {
          span {
            font-size: 30px;
            line-height: 64px;
          }
          p {
            font-size: 44px;
            font-weight: bold;
            line-height: 90px;
          }
          .price{
            font-size: 32px;
          }
        }
      }
    }
    .member-footer {
      width: 100%;
      box-sizing: border-box;
      padding: 44px;
      position: fixed;
      bottom: 0;
      z-index: 1000;
      .tips{
        font-size: 40px;
        line-height: 36px;
        color: #FB7214;
        margin-bottom: 24px;
      }
      .member-total {
        width: 100%;
        height: 96px;
        background-color: #222c3a;
        border-radius: 16px;
        margin-bottom: 20px;
        font-size: 30px;
        color: #b9bdc6;
        align-items: center;
        box-sizing: border-box;
        padding: 0 28px;
        .left{
          p {
            color: #fff;
            margin-left: 20px;
            font-weight: 500;
          }
        }
        .right{
          .right-item{
            margin-left: 40px;
            p {
              color: #fff;
              font-weight: 500;
            }
            &:last-child{
              color: #F5D6B1;
              p {
              color: #F5D6B1;
              font-weight: 500;
            }
            }
          }
        }
        
      }
      .member-btn {
        width: 100%;
        height: 96px;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 0 24px;
        box-sizing: border-box;
        align-items: center;
        .cancel {
          width: 290px;
          height: 70px;
          border-radius: 16px;
          background-color: #e7f3ff;
          font-size: 30px;
          color: #0068f0;
          font-weight: bold;
        }
        .save {
          flex: 1;
          margin-left: 24px;
          height: 70px;
          border-radius: 16px;
          font-size: 30px;
          color: #ffffff;
          font-weight: bold;
          background-color: #0068f0;
        }
      }
    }
  }
  .remark-box {
    width: 100%;
    height: 100%;
    .remark {
      width: 744px;
      background-color: #fff;
      border-radius: 32px;
      padding: 56px 44px;
      .remark-title {
        font-size: 40px;
        line-height: 40px;
        color: #1a1e22;
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
      }
      .remark-content {
        width: 100%;
        .remark-name {
          font-size: 40px;
          line-height: 46px;
          margin-bottom: 16px;
        }
        .remark-list {
          margin-bottom: 80px;
          max-height: 400px;
          overflow: auto;
          .remark-item {
            width: 100%;
            background: #f4f4f4;
            border-radius: 4px;
            margin-bottom: 16px;
            box-sizing: border-box;
            padding: 24px;
            flex-shrink: 0;
            .remark-text {
              font-size: 32px;
              line-height: 46px;
              color: #666666;
              margin-bottom: 16px;
              font-weight: 500;
            }
            .remark-message {
              font-size: 24px;
              line-height: 34px;
              color: #999999;
              span {
                width: 240px;
              }
              p {
                margin-left: 12px;
              }
            }
          }
        }
      }
      .remark-close {
        width: 100%;
        height: 70px;
        background-color: #0068f0;
        font-size: 30px;
        font-weight: 500;
        border-radius: 16px;
        margin-top: 30px;
        color: #fff;
      }
    }
  }
}
.nav-box {
  width: 100%;
  padding: 70px 30px;
  .nav-content {
    width: 100%;
    position: relative;
    .back {
      position: absolute;
      left: 0px;
      img {
        width: 44px;
        height: 44px;
      }
      span {
        font-size: 44px;
        font-weight: 700;
        color: #1a1e22;
        margin-left: 20px;
      }
    }
    .title {
      font-size: 44px;
      font-weight: 700;
      color: #1a1e22;
    }
  }
}
</style>